<template>
	<div class="recomendations">
		<div class="recomendations__title">Полезные статьи и рекомендации</div>

		<div v-if="content" class="recomendations__content content-rec">
			<div class="content-rec__image-container">
				<tk-image
					:src="content.preview"
					class="content-rec__image-container--img"
          width="420"
          height="250"
				/>
			</div>

			<h3 class="content-rec__title">{{ content.title }}</h3>

			<p class="content-rec__desc" v-html="content.content" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			content: {},

      getAbortController: new AbortController(),
		}
	},
	async beforeMount() {
		const response = await this.$api.articles.get({
			id: this.$route.params.alias
		}, { controller: this.getAbortController })

		if (response && response.status) {
			this.content = response.response
		}
	},
  beforeUnmount() {
    this.getAbortController.abort()
  }
}
</script>

<style lang="sass" scoped>
.recomendations
  position: relative
  &__title
    font-weight: bold
    font-size: 24px
    line-height: 24px
    margin-bottom: 30px

  &__list
    display: flex
    flex-wrap: wrap

  &__form
    width: 420px
    position: relative
    &:before
      content: url('~@/assets/images/svg/magnifying-glass.svg')
      position: absolute
      top: 15px
      left: 15px
  &__input
    width: 100%
    height: 55px
    padding: 15px 15px 15px 45px
    background: #FFFFFF
    border: 1px solid #CBCBCB
    border-radius: 12px
    font-size: 12px
    line-height: 12px
    margin-bottom: 30px
    caret-color: #1AC386
    &::-webkit-search-cancel-button
      -webkit-appearance: none
      background: url('~@/assets/images/svg/close-gray.svg') center no-repeat
      width: 20px
      height: 20px
  &__link
    position: relative
    display: flex
    align-items: center
    height: 55px
    width: 530px
    padding-right: 25px
    padding-left: 15px
    background: #FFFFFF
    font-size: 14px
    line-height: 12px
    border-radius: 12px
    box-shadow: 0px 0px 17px -6px lightgrey
    margin-bottom: 10px
    cursor: pointer
    transition: 0.1s linear
    &:before
      content: url('~@/assets/images/svg/arrow-right.svg')
      position: absolute
      right: 15px
    &:hover
      background: #1AC386
      color: #ffffff
      &.recomendations__link::before
        content: url('~@/assets/images/svg/arrow-right-white.svg')
  .content-rec
    &__image-container
      max-width: 420px
      width: 100%
      height: 250px
      border-radius: 12px
      overflow: hidden
      img
        object-fit: cover
    &__title
      margin-top: 30px
      margin-bottom: 15px

      font-style: normal
      font-weight: 600
      font-size: 20px
      line-height: 18px
      color: #3B3F47

    &__desc
      margin: 0
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 20px
      color: #3B3F47


// 540
@media screen and (max-width: 800px)
  .recomendations
    width: 100%
    .article-recomend
      width: 100%
      &__img
        img
          width: 100%
          height: auto
</style>
